import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base.service';
import { Storage } from '@ionic/storage-angular';
import { DetailEventModel, Member } from 'src/app/models/event/detail-event.model';
import { ActivatedRoute } from '@angular/router';
import { AllEventModel } from 'src/app/models/event/all-event.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  constructor(protected http: HttpClient, protected storage: Storage, public route: ActivatedRoute) 
  {
    super(http);
  }

    // Zda jsou event v local storage
    public async existsEventsInStorage() : Promise<boolean> {
      if((await this.storage.get("Events"))) {
        return true;
      } else {
        return false;
      }
    }

  // Zda jsou event v local storage
  public async existsEventInStorage(identifier: string) : Promise<boolean> {
    if((await this.storage.get("Event_" + identifier))) {
      return true;
    } else {
      return false;
    }
  }

  // Uloží event do storage
  public async saveEventToStorage(event: DetailEventModel, identifier: string) {
    await this.storage.set("Event_" + identifier, JSON.stringify(event));
  }

  // Uloží eventy do storage
  public async saveEventsToStorage(events: AllEventModel[]) {
    await this.storage.set("Events", JSON.stringify(events));
  }

  // Vrátí eventy z api
  public async all(): Promise<Observable<AllEventModel[]>> {
    return this.get<AllEventModel[]>("/Event/All").pipe(map(x => x["events"]));
  }

  // Vrátí event z api
  public async detail(identifier: string): Promise<Observable<DetailEventModel>> {
    return this.get<DetailEventModel>("/Event/Detail/" + identifier);
  }

  // Vrátí eventy ze storage
  public async eventsFromStorage() : Promise<AllEventModel[]> {
    return JSON.parse(await this.storage.get("Events"));
  }

  // Vrátí event ze storage
  public async detailEventFromStorage(identifier: string) : Promise<DetailEventModel> {
    return JSON.parse(await this.storage.get("Event_" + identifier));
  }

  // Vrátí uložené členy
  public async members(identifier: string) : Promise<Member[]> {
    return JSON.parse((await this.storage.get("Members_" + identifier)));
  }

  // Uloží členy
  public async saveMembers(members: Member[], identifier: string) {
    await this.storage.set("Members_" + identifier, JSON.stringify(members) );
  }

  // Smaže event za paměti
  public async deleteEvent(identifier: string) {
    await this.storage.remove("Event_" + identifier);
  }

  // Smaže eventy za paměti
  public async deleteEvents() {
    await this.storage.remove("Events");
  }
}
