import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  public apiUrl: string = environment.apiUrl; 

  constructor(protected http: HttpClient) {
    
  }

  get<TValue>(apiFunc: string) : Observable<TValue> {
    let url = this.apiUrl + apiFunc;
    return this.http.get<TValue>(url).pipe(map(x => x["data"]));
  }
}
