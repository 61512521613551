import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { forkJoin } from 'rxjs';
import { AllEventModel } from 'src/app/models/event/all-event.model';
import { DetailEventModel } from 'src/app/models/event/detail-event.model';
import { EventService } from '../event/event.service';
import { GlobalIdentifierService } from '../global-identifier.service';

@Injectable({
  providedIn: 'root'
})
export class EventRefreshService {
  identifier: string;

  constructor(public loadingController: LoadingController, public eventService: EventService, private globalIdentifierService: GlobalIdentifierService) { }

  public async refreshEvents() {
    this.globalIdentifierService.getObservable().subscribe((identifier) => {
      this.identifier = identifier;
    });

    const loading = await this.loadingController.create({
      message: 'Prosím, čakajte...'
    });
    
    await loading.present();

    await this.eventService.deleteEvent(this.identifier);
    await this.eventService.deleteEvents();

    var event = (await this.eventService.detail(this.identifier));
    var events = (await this.eventService.all());

    forkJoin<DetailEventModel, AllEventModel[]>([event, events]).subscribe(async x => {
      this.eventService.saveEventToStorage(x[0], this.identifier);
      this.eventService.saveEventsToStorage(x[1]);
      await loading.dismiss();
    });
  }
}
