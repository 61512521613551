<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" (click)="closeMenu()"> 
      <ion-content class="menu-overlay ion-align-items-center">
        <ion-list class="menu p-menu" id="inbox-list">
          <ion-menu-toggle class="menu-toogle" auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item class="ion-text-center menu-item" text-align-center routerDirection="root" [routerLink]="this.identifier + [p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-label class="label" color="light">{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-toogle" (click)="this.deleteEvent()" auto-hide="false">
            <ion-item class="ion-text-center menu-item" text-align-center lines="none" detail="false">
              <div style="width: 100%;" class="ion-justify-content-center">
              <ion-button color="primary">Aktualizovať dáta</ion-button>
            </div>
            </ion-item>
          </ion-menu-toggle>
          <!-- <ion-menu-toggle class="menu-toogle" (click)="this.deleteData()" auto-hide="false">
            <ion-button color="primary">Aktualizovať dáta</ion-button>
          </ion-menu-toggle>   -->
        </ion-list>
        <!-- <div id="prdel" class="square" #button></div> -->
      </ion-content>
    </ion-menu>
    <div class="ion-page" id="main">
      <ion-content class="ion-padding">
        <ion-menu-toggle class="menu-toogle" (click)="this.deleteEvent()" auto-hide="false">
          <ion-button color="primary">Aktualizovať dáta</ion-button>
        </ion-menu-toggle>
      </ion-content>
    </div>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
