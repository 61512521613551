import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, MenuController, ModalController, Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Animation, AnimationController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { EventService } from './services/event/event.service';
import { HttpParams } from '@angular/common/http';
import { GlobalIdentifierService } from './services/global-identifier.service';
import { forkJoin } from 'rxjs';
import { DetailEventModel } from './models/event/detail-event.model';
import { AllEventModel } from './models/event/all-event.model';
import { EventRefreshService } from './services/eventRefresh/event-refresh.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  identifier: string;

  constructor(private menuController: MenuController, public loadingController: LoadingController, 
    private storage: Storage, public route: ActivatedRoute, private eventRefreshService: EventRefreshService, private globalIdentifierService: GlobalIdentifierService) {
    
  }

  async ngOnInit() {
    await this.storage.create();

    this.globalIdentifierService.getObservable().subscribe((identifier) => {
      this.identifier = identifier;
    });
  }

  public async deleteEvent() {
    await this.eventRefreshService.refreshEvents();
  }

  public appPages = [
    { title: 'Domov', url: "/home" },
    { title: 'Zoznam účastníkov', url: "/delegation-members" },
    { title: 'Program', url: "/program" },
    { title: 'Užitočné informácie', url: "/useful-informations" },
    { title: 'Informácie o krajine', url: "/page-country" },
    { title: 'Mapy', url: "/page-map" },
    { title: 'Archív', url: '/page-archive' },
    { title: 'Sacka', url: "/sacka" },
    { title: 'O aplikácii', url: "/page-about-us" }
  ];

  public closeMenu() {
    this.menuController.close();
  }
}
