import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { EventRefreshService } from '../services/eventRefresh/event-refresh.service';

@Injectable()
export class KreativgangErrorHandler extends ErrorHandler {

  constructor(public loadingController: LoadingController, private alertController: AlertController, private eventRefreshService: EventRefreshService) {
    super();
  }

  async handleError(error: any): Promise<void> {
    this.loadingController.dismiss();
    await this.presentAlert();
    super.handleError(error);
  }
 
  async presentAlert() {
    const alert = await this.alertController.create({
      header: "Error",
      subHeader: "V aplikácii sa vyskytla neočakávaná chyba",
      buttons: [
        {
          text: "Cancel",
          handler: () => {

          }
        },
        {
          text: "Aktualizovať dáta",
          handler: () => {
            this.eventRefreshService.refreshEvents();
          }
        }
      ]
    });

    await alert.present();
  }
}