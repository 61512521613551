import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalIdentifierService {

  private identifierSubject = new Subject<string>();

  publishIdentifier(identifier: string) {
      this.identifierSubject.next(identifier);
  }

  getObservable(): Subject<string> {
      return this.identifierSubject;
  }
}
