

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: ':identifier/home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: ':identifier/delegation-members',
    loadChildren: () => import('./pages/delegationMemeber/delegation-members/delegation-members.module').then( m => m.DelegationMembersPageModule)
  },
  {
    path: 'delegation-members',
    loadChildren: () => import('./pages/delegationMemeber/delegation-members/delegation-members.module').then( m => m.DelegationMembersPageModule)
  },
  {
    path: ':identifier/delegation-member/:id',
    loadChildren: () => import('./pages/delegationMemeber/delegation-member/delegation-member.module').then( m => m.DelegationMemberPageModule)
  },
  {
    path: 'delegation-member/:id',
    loadChildren: () => import('./pages/delegationMemeber/delegation-member/delegation-member.module').then( m => m.DelegationMemberPageModule)
  },
  {
    path: ':identifier/program',
    loadChildren: () => import('./pages/program/program/program.module').then( m => m.ProgramPageModule)
  },
  {
    path: 'program',
    loadChildren: () => import('./pages/program/program/program.module').then( m => m.ProgramPageModule)
  },
  {
    path: ':identifier/useful-informations',
    loadChildren: () => import('./pages/usefulInformation/useful-informations/useful-informations.module').then( m => m.UsefulInformationsPageModule)
  },
  {
    path: 'useful-informations',
    loadChildren: () => import('./pages/usefulInformation/useful-informations/useful-informations.module').then( m => m.UsefulInformationsPageModule)
  },
  {
    path: ':identifier/page-country',
    loadChildren: () => import('./pages/pageCountry/page-country/page-country.module').then( m => m.PageCountryPageModule)
  },
  {
    path: 'page-country',
    loadChildren: () => import('./pages/pageCountry/page-country/page-country.module').then( m => m.PageCountryPageModule)
  },
  {
    path: ':identifier/page-map',
    loadChildren: () => import('./pages/pageMap/page-map/page-map.module').then( m => m.PageMapPageModule)
  },
  {
    path: 'page-map',
    loadChildren: () => import('./pages/pageMap/page-map/page-map.module').then( m => m.PageMapPageModule)
  },
  {
    path: ':identifier/page-archive',
    loadChildren: () => import('./pages/pageArchive/page-archive/page-archive.module').then( m => m.PageArchivePageModule)
  },
  {
    path: 'page-archive',
    loadChildren: () => import('./pages/pageArchive/page-archive/page-archive.module').then( m => m.PageArchivePageModule)
  },
  {
    path: ':identifier/sacka',
    loadChildren: () => import('./pages/sacka/sacka/sacka.module').then( m => m.SackaPageModule)
  },
  {
    path: 'sacka',
    loadChildren: () => import('./pages/sacka/sacka/sacka.module').then( m => m.SackaPageModule)
  },
  {
    path: ':identifier/page-about-us',
    loadChildren: () => import('./pages/pageAboutUs/page-about-us/page-about-us.module').then( m => m.PageAboutUsPageModule)
  },
  {
    path: 'page-about-us',
    loadChildren: () => import('./pages/pageAboutUs/page-about-us/page-about-us.module').then( m => m.PageAboutUsPageModule)
  },
  {
    path: ':identifier/events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
